import axios from 'axios';
import configs from '../configs'

const authenticateApp = async (applicationId,applicationToken) => {
    /**
     * Credenciais para autenticação na aplicação
     */
    const body = {
        applicationId,
        applicationToken
    };

    try {
        const response = await axios.post(
            `${configs.novaAPI.BASE_URL}/auth/application/authorize`,
            body
        )
        return response.data.data;
    } catch(e) {
        return e;
    }
};

const getUserToken = async (username, password) => {
    let appToken;

    // Autentica a aplicação
    try {
        /**
         * Token para autenticação da aplicação
         */
        appToken = await authenticateApp(
            configs.novaAPI.applicationId,
            configs.novaAPI.applicationToken
        );
    } catch (e) {
        return e;
    }

    const headers = {
        'app-authorization': appToken
    };

    /**
     * Credenciais para realizar o login do usuário na API
     */
    const body = {
        username,
        password,
        applicationId: configs.novaAPI.applicationId
    };

    try {
        const response = await axios.post(
            `${configs.novaAPI.BASE_URL}/auth/user/login`,
            body,
            { headers }
        )
        return response.data.data;
    } catch(e) {
        return e;
    }
};

const login = async () => {
    try {
        const token = await getUserToken(configs.novaAPI.username, configs.novaAPI.password);
        return token;
    } catch (e) {
        return e;
    }
};

const getCategories = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/categories`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const getAlbuns = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/albums`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const getAlbumById = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/albums/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return  e;
    }
}

const saveAlbuns = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/albums`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}


const saveCategory = async (name, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/categories`,
            { name },
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const deleteCategory = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/categories/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const deleteAlbum = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/albums/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}


const getNews = async (token, params) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios.get(`${configs.novaAPI.BASE_URL}/site/news`, { params, headers })

        return result.data.data
    } catch(e) {
        return e;
    }
}

const getNewsById = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/news/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const save = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/news`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const upload = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/file-upload/s3`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const edit = async (id, data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    try {
        const result = await axios
        .put(
            `${configs.novaAPI.BASE_URL}/site/news/${ id }`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const remove = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/news/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const getAttachments = async (token, page, order) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    var pagina = page ? page : 1;
    var ordem = order ? order : 'id-desc';

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/attachments?page=` + pagina + '&orderby=' + ordem,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const saveAttachment = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/attachments`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const removeAttachment = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/attachments/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const getAttachmentTypes = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/attachments-type`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const saveAttachmentType = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/attachments-type`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const removeAttachmentType = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/attachments-type/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

export default {
    authenticateApp,
    getUserToken,
    login,
    getCategories,
    saveCategory,
    deleteCategory,
    edit,
    save,
    getNews,
    getNewsById,
    getAlbumById,
    remove,
    upload,
    getAttachments,
    saveAttachment,
    removeAttachment,
    getAttachmentTypes,
    saveAttachmentType,
    getAlbuns,
    deleteAlbum,
    saveAlbuns,
    removeAttachmentType
}
