<template lang="pug">
.main-wrapper
    Dialog(header='Remover Notícia' :visible.sync='dialog.apagar' :modal='true')
        p Deseja remover a notícia?
        .ta-center.my-4
            Button.p-button-danger(label='Remover' @click='remove()')

    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Notícias</b>

    Panel.mb-2.filters(header='Filtros' :toggleable='true')
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-xl-5.p-lg-4
                label.form-label Título:
                .p-inputgroup
                    InputText(v-model='filters.title' @keyup.enter.native='getList()' placeholder='Título da notícia')
                    Button.min-w-10(icon='jam jam-search' @click='getList()')
                    Button.min-w-10.p-button-danger(v-if='filters.title' icon='jam jam-rubber' @click="filters.title = ''; getList()")
            .p-col-12.p-xl-5.p-lg-4
                label.form-label Subtítulo:
                .p-inputgroup
                    InputText(v-model='filters.subtitle' @keyup.enter.native='getList()' placeholder='Subtítulo da notícia')
                    Button.min-w-10(icon='jam jam-search' @click='getList()')
                    Button.min-w-10.p-button-danger(v-if='filters.subtitle' icon='jam jam-rubber' @click="filters.subtitle = ''; getList()")
            .p-col-12.p-xl-2.p-lg-3
                label.form-label Data de Publicação:
                .p-inputgroup
                    Calendar(v-model='filters.publish_date' dateFormat="dd/mm/yy" :locale="ptbr" :manualInput='true' :maxDate='moment()._d' @input='getList()' @keyup.enter.native='getList()' placeholder='DD/MM/AAAA' :showIcon='!filters.publish_date')
                    Button.min-w-10.p-button-danger(v-if='filters.publish_date' icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click="filters.publish_date = null; getList()")
            .p-col-12.p-xl-3.p-lg-4
                label.form-label Categoria:
                .p-inputgroup(v-if='categories.length')
                    MultiSelect.max-w-80(v-model='filters.categories' :options='categories' dataKey='id' optionValue='id' optionLabel='name' placeholder='Selecione...' :style='{maxWidth: `${filters.categories.length ? 80 : 90}%`}' @keyup.enter.native='getList()')
                    //- InputText(placeholder='Saúde, Curiosidade...')
                    Button.min-w-10(icon='jam jam-search' @click='getList()')
                    Button.min-w-10.p-button-danger(v-if='filters.categories.length' icon='jam jam-rubber' @click='filters.categories = []; getList()')
                ProgressBar(v-else mode='indeterminate')
            .p-col-12.p-md-4
                label.form-label Status:
                Dropdown(
                    v-model='filters.status'
                    :options='options.ieStatus'
                    optionLabel='text'
                    optionValue='value'
                    dataKey='value'
                    placeholder='TODOS'
                    @change='applyFilters()'
                )
    .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push(`/noticias/salvar`)")

    ProgressBar(v-if="waiting || waitingApagar" mode="indeterminate")
    div(v-else-if='!list.length')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        Panel.datatable(header='Notícias')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:autoLayout='true' :value="list" responsiveLayout="scroll")
                Column(headerStyle='width: 5%; font-weight: bold' field='status' :bodyStyle="{textAlign:'center'}" header='Status')
                    template(#body='{data}')
                        ProgressSpinner.waitingStatus(v-if='statusPendentes.includes(+data.id)' strokeWidth='1' v-tooltip.top='statusPendentes.includes(+data.id)')
                        .status-indicator(v-else :class="{ off: data.status == '0'  }" @click='alterarStatus(data)' v-tooltip.top="data.status == '0' ? 'Ativar' : 'Desativar'")
                            i.jam(:class="{ 'jam-check': data.status == '1', 'jam-minus': data.status == '0' }")

                Column(headerStyle='width: 5%; font-weight: bold' field='id' header='id' :bodyStyle="{textAlign:'center'}")

                Column(headerStyle='width: 25%;' field='title' header='Título')

                Column(headerStyle='width: 20%;' field='subtitle' header='Subtítulo')

                Column(headerStyle='width: 15%;' field='categories.name' header='Categoria')
                
                Column(headerStyle='width: 15%; text-align: center' header='Publicação' field='publish_date')
                    template(#body='{data}')
                        .ta-center
                            span {{ moment(data.publish_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}

                Column(headerStyle='width: 15%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Visualizar'"
                                icon="jam jam-document"
                                @click="view(props.data.id)"
                            )
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="edit(props.data.id)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar(props.data.id)"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss" scoped>
.dialogApagar {
    .p-progress-spinner {
        width: 29px;
        height: auto;
    }
}
.datatable {
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 24px;
        height: 24px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #94c860;
        border: 1px solid #84b553;
        transition: 250ms;
        cursor: pointer;
        &:hover { background-color: #84b553; }
        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &:hover { background-color: #b33030; }
        }
        .jam {
            color: #fff;
        }
    }
   
}
.filters{
    .min-w-10 {
        min-width: 10%;
    }
}
</style>

<script>
import Noticias from '../../middleware/controllers/Noticias'
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressbar";
import Dialog from 'primevue/dialog'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import Paginator from 'primevue/paginator';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect';

import { pCalendarLocale_ptbr} from './../../utils'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: "PerguntasFrequentes",
    components: { ProgressBar, Dialog, Paginator, DataView, Panel, DataTable, Column, Button, 
        Tooltip, InputText, ProgressSpinner, Calendar, MultiSelect, Dropdown },
    directives: { tooltip: Tooltip },
    data() {
        return {
            waiting: false,
            waitingApagar: false,
            dialog: {
                apagar: false
            },
            idASerApagado: '',
            list: [],
            tokenAPI: '',
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: 10,
                count: 0
            },
            filters: {
                title: '',
                subtitle: '',
                categories: [],
                publish_date: null,
                status: '1'
            },
            options:{
                ieStatus: [
                    { text: "TODOS", value: null },
                    { text: "Ativo", value: '1' },
                    { text: "Inativo", value: '0' },
                ]
            },
            ptbr: pCalendarLocale_ptbr,
            categories: [],
            statusPendentes: []
        };
    },
    created() {
        //Login na nova API
        Noticias.login().then(result => {
            this.tokenAPI = result;
            this.getList()
            Noticias.getCategories(this.tokenAPI).then(res => {
                if(res.rows) this.categories = res.rows
                else this.$toast.error('Erro ao buscar Categorias')
            })
        })
    },
    methods: {
        applyFilters () {
            let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
            this.getList(params)
        },
        getList(page) {
            this.waiting = true
            this.paginator.page = page || 1
            const params = { page: this.paginator.page, orderby: 'publish_date' }
            _.each(this.filters, (val,key) => {
                if(val){
                    if (key == 'status'){
                        params[key] = this.filters.status
                    }else{
                        if(_.isString(val)) params[key] = `%${val}%`
                        if(_.isArray(val) && !_.isEmpty(val)) params[key] = val.toString()
                        if(_.isDate(val)) params[key] = moment(val).format('YYYY-MM-DD')
                    }
                }
            })
            console.log(params)
            Noticias.getNews(this.tokenAPI, params)
            .then(response => {
                if(response.rows) {
                    this.list = response.rows
                    this.paginator.count = response.total 
                }
                this.waiting = false
            })
        },
        onPage(event) {
            this.waiting = true;
            this.getList(event.page + 1)
        },
        dialogApagar(id) {
            this.idASerApagado = id
            this.dialog.apagar = true
        },
        remove() {
            this.waiting = true
            Noticias.remove(this.idASerApagado, this.tokenAPI).then(response => {
                this.dialog.apagar = false
                if(! response)
                    this.$toast.error('Não foi possível remover a notícia.', { duration: 3000 })
                else {
                    this.getList()
                    this.$toast.success('Notícia removida com sucesso.', { duration: 3000 })
                }

                this.waiting = false
            })
        },
        view(id) {
            // this.$router.push(`/noticias/visualizar/${id}/`)
            window.open('https://medclub.com.br/noticia/' + id, '_blank').focus();
        },
        edit(id) {
            this.$router.push(`/noticias/salvar/${id}/`)
        },
        moment,
        async alterarStatus(data) {
            this.statusPendentes.push(data.id)
            data['status'] = data['status'] == '0' ? '1' : '0'
            await Noticias.edit(data.id, { status: data.status }, this.tokenAPI).then(res => {
                if(res.id)
                    this.statusPendentes.splice(this.statusPendentes.indexOf(res.id), 1)
            })
        }
    },

};
</script>
